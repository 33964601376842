body{background:#FFF}
h2, .h2{color:#666}
.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {background-color: #666; border-color: #666;}.pagination > li > a, .pagination > li > span{color:#666;}
#wrapper{background:#fff}
#header{background:#ccc url(../images/imgd143series/header-bg2.png) repeat-x top;color:#FFF}
#headercontent a{color:#666}
.slogan{color:#666}
nav#nav-principal{background:#A0A0A0;border-top:1px solid #cecccc;border-bottom:1px solid #666}
nav#nav-principal a{color:#FFF}
nav#nav-principal p{color:#FFF}
div#menu{background:#A0A0A0 url(../images/imgd143series/menu-bg2.png) repeat-x top}
div#menu ul li a{color:#FFF}
#content_top{background:#ccc url(../images/imgd143series/contenttop-bg.png) repeat-x top}
.produits{border-top:2px solid #131719;border-bottom:2px solid #131719;border-left:2px solid #666;border-right:2px solid #666}
.produits p.nomprod{color:#666}
.produits p.titreprod:hover{color:#131719}
.produits:hover{border-right:2px solid #131719;border-left:2px solid #131719;border-bottom:2px solid #666;border-top:2px solid #666}
.remise{background:url(../images/vignette_promo.png) no-repeat top left;color:#FFF}
.produits a.addbasket{background:url(../images/panier_add.png) no-repeat}
.galerie{border-bottom:3px solid #FFF}
.galerie:hover{border-bottom:3px solid #666}
.galerie img{border:1px solid silver}
.diaporama{color:#000;border-top:1px solid #ccc}
.diaporama a{color:#000}
.diaporama a:hover{color:#666}
.message_lo{background:#eee;border:1px solid #666}
.message_lo p.web a{color:#222}
a.addmsglo{color:#FFF;background:#666;border:1px solid #ccc}
#addmsg input{border:1px solid #ccc}
#addmsg textarea{border:1px solid #ccc}
#addmsg input[type=submit]{background:#eee;border:1px solid #ccc}
#addmsg input[type=submit]:hover{background:#fff}
#addmsg a{color:#000}
#addmsg a:hover{color:#AF9C78}
#contactright{border:2px solid #666}
#form1 input,#form1 textarea{border:1px solid #bbb;background:#fff}
#form1 input.button{color:#FFF;background:#666;border:1px solid #ccc}
#footer{background:#ccc url(../images/imgd143series/header-bg2.png) repeat-x top;color:#FFF;border-top:3px solid #A0A0A0}
#footer a{color:#FFF}
#pub{background:#ccc;color:#000}
h4:first-letter, .h4:first-letter,.galerie a:hover,#form1 p:first-letter{color:#666}
#headercontent a:hover,div#menu ul li a:hover,#contentpage a,.galerie a{color:#000}
nav#nav-principal li:hover > a, nav#nav-principal li.actif > a{color:#000}
nav#nav-principal li li:hover > a, nav#nav-principal li li.actif > a{color:#a0a0a0}
.galerie img:hover,#form1 input:hover,#form1 textarea:hover{border:1px solid #666}
a.addmsglo:hover,#form1 input.button:hover{background:#444}
.button,#paiementchoix .button{color:#FFF;background:#666;border:1px solid #ccc}
.button:hover,#paiementchoix .button:hover{border:1px solid #666;background:#444}
.pagination.pull-right li.active a{color:#fff !important;}

/** Flux Panier **/

.button.button-secondary {
  background: orange;

  &:hover {
    background: #444;
  }
}